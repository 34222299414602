<template>
  <div>
    <b-card>
      <b-link @click="goBack">
        <feather-icon
          class="position-absolute text-primary"
          size="40"
          icon="ArrowLeftCircleIcon"
        />
      </b-link>
      <b-row>
        <b-col class="text-center">
          <h1><feather-icon
            class="mr-2"
            icon="ToolIcon"
            size="25"
          />Tipi di Giustificativi</h1>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="p-2">
      <b-row>
        <b-col cols="6">
          <h3 class="text-center mb-3">
            Aggiungi Tipo
          </h3>
          <b-form-input
            v-model="newType"
            type="text"
            placeholder="Inserisci Nuovo Tipo qui"
          />
          <div class="text-right mt-1">
            <b-button
              variant="primary"
              size="sm"
              @click="addType"
            >
              Aggiungi
            </b-button>
          </div>
        </b-col>
        <b-col cols="6">
          <h3 class="text-center mb-3">
            Lista dei Tipi
          </h3>
          <b-list-group :key="componentKey">
            <b-list-group-item
              v-for="(type, key) in types"
              :key="type.id"
              class="d-flex align-items-center justify-content-between"
            >
              <h6 class="mb-0 text-capitalize">
                {{ type.name }}
              </h6>
              <div class="d-flex align-items-center">
                <b-form-checkbox
                  v-model="type.no_approvation == 1 ? true : false"
                  switch
                  @change="setNoApprovation($event, type.id)"
                />
                <b-button
                  variant="danger"
                  size="sm"
                  style="padding:4px;"
                  class="rounded-circle"
                  @click="deleteType(key)"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </div>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BListGroup, BListGroupItem, BButton, BFormInput, BLink, BFormCheckbox,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import justificationsStoreModule from '../justificationsStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BButton,
    BFormInput,
    BLink,
    BFormCheckbox,
  },
  setup() {
    const JUSTIFICATIONS_APP_STORE_MODULE_NAME = 'app-justifications'

    // Register module
    if (!store.hasModule(JUSTIFICATIONS_APP_STORE_MODULE_NAME)) {
      store.registerModule(JUSTIFICATIONS_APP_STORE_MODULE_NAME, justificationsStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(JUSTIFICATIONS_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(JUSTIFICATIONS_APP_STORE_MODULE_NAME)
      }
    })

    const types = ref(null)
    store
      .dispatch('app-justifications/fetchJustificationTypes', {})
      .then(response => {
        types.value = response.data[0]
      })
      .catch(error => {
        if (error === 401) {
          localStorage.removeItem('userData')
          store.commit('user/updateUserData', null)
          window.location.reload(true)
        }
      })

    return {
      types,
      componentKey: 0,
      newType: null,
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    setNoApprovation(e, id) {
      const formData = {
        no_approvation: e === true ? 1 : 0,
      }
      store
        .dispatch('app-justifications/updateJustificationType', { id, formData })
        .then(result => {
          if (e === true) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Tipo di Giustificativo impostato senza Approvazione',
                icon: 'ClipboardIcon',
                variant: 'success',
              },
            })
          }
          if (e === false) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Tipo di Giustificativo impostato con Approvazione',
                icon: 'ClipboardIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
    },
    deleteType(key) {
      const { id } = this.types[key]
      store
        .dispatch('app-justifications/deleteJustificationType', { id })
        .then(result => {
          this.$delete(this.types, key)
          this.componentKey++
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Tipo di Giustificativo eliminato con successo',
              icon: 'ClipboardIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
    },
    addType() {
      const valid = this.validation()
      if (!valid) return
      store
        .dispatch('app-justifications/addJustificationType', { name: this.newType })
        .then(result => {
          this.newType = null
          const newType = {
            id: result.data.id,
            name: result.data.name,
          }
          this.types.push(newType)
          this.componentKey++
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Tipo di Giustificativo aggiunto con successo',
              icon: 'ClipboardIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
    },
    validation() {
      if (!this.newType) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Errore Nuovo Tipo',
            icon: 'AlertTriangleIcon',
            text: 'Inserire il nome del nuovo Tipo per proseguire.',
            variant: 'danger',
          },
        })
        return false
      }
      return true
    },
  },
}
</script>

<style>
.position-absolute {
  z-index: 1;
}
</style>
